let menuMob = ()=> {
    document.querySelector('.header__btn-box').insertAdjacentHTML('beforeEnd', `<div class="header__menu-mobile"><div class="header__menu-mobile_btn_box"><a href="#" class="header__menu-mobile_btn"></a></div><div class="header__menu-mobile_box"></div></div>`)
 
    let cloneAlternative = document.querySelector('.header__logo').cloneNode(true)
    let cloneMenuB = document.querySelector('.header__menu').cloneNode(true)

    document.querySelectorAll('.first-screen__slider_text-box').forEach(e=>{
        let cloneBtn = document.querySelector('.header__button').cloneNode(true)
        e.appendChild(cloneBtn) 
    })
    
    document.querySelector('.header__menu-mobile_box').appendChild(cloneAlternative)
    document.querySelector('.header__menu-mobile_box').appendChild(cloneMenuB)
}
menuMob()
document.querySelector('.header__menu-mobile_btn').addEventListener('click', (e) => {
    e.stopPropagation()
    e.preventDefault()
    console.log(e.target)
    e.target.classList.toggle('_active')
    e.target.closest('.header__menu-mobile').classList.toggle('_active')
})